<template>
  <v-container>
    <v-row align="center">
      <v-col
        ><h3>Manage your product surplus and prevent food waste.</h3>
        Last Calls will be cleared every midnight</v-col
      >
    </v-row>
    <v-row align="center">
      <v-col cols="10" sm="4" lg="2">
        <v-text-field
          slot="input"
          label="OFF"
          v-model="defaultOff"
          single-line
          autofocus
          append-icon="%"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="8" sm="5" lg="4">
        <v-text-field
          slot="input"
          label="Description"
          v-model="description"
          single-line
          autofocus
        ></v-text-field>
      </v-col>
      <v-col cols="2" sm="2">
        <v-btn color="info" class="mr-3" small @click.stop="saveLastCall"
          >Save</v-btn
        >
        <v-btn icon small @click.stop="saveLastCall" to="/product/menu">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <draggable
            id="id"
            v-model="products"
            @start="drag = true"
            @end="drag = false"
            v-bind="dragOptions"
            @add="newProduct"
          >
            <v-card-text>
              <v-list v-if="lastCall.items && lastCall.items.length > 0">
                <v-list-item
                  v-for="(product, key) in productInLastCall(lastCall)"
                  :key="key"
                >
                  <template v-if="product">
                    <v-list-item-avatar v-if="dishImage(product)">
                      <img :src="dishImage(product)" />
                    </v-list-item-avatar>
                    <v-list-item-avatar v-else>
                      <v-icon class="grey--text">mdi-image-off-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>{{
                      product.name
                    }}</v-list-item-content>
                    <v-list-item-content>{{
                      product.amount_left
                    }}</v-list-item-content>
                    <v-list-item-content>{{
                      product.off ? product.off + '%' : null
                    }}</v-list-item-content>
                    <v-list-item-content>
                      {{
                        (product.price - (product.price * product.off) / 100)
                          | currency
                      }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon
                        color="red"
                        @click="removeProduct(product.id, lastCall.id)"
                        small
                        >clear</v-icon
                      >
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item>Drag and drop items here.</v-list-item>
              </v-list>
            </v-card-text>
          </draggable>
        </v-card>
      </v-col>
    </v-row>

    <div>
      <v-row align="center">
        <v-col cols="2" sm="2">
          <h4>Products</h4>
        </v-col>
        <v-col cols="6" sm="4">
          <v-text-field
            placeholder="Search product name"
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col cols="4" sm="3">
          <v-checkbox
            value="true"
            label="Not in Last Call?"
            v-model="notInLastCall"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mb-4">
        <div v-for="(product, key) in filteredDishList" :key="key">
          <draggable
            :id="product.id"
            v-model="products"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            :move="chooseProduct"
          >
            <v-col>
              <v-card class="mx-auto" max-width="244" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      {{ product.price | currency }}
                    </div>
                    <v-list-item-title class="mb-1">
                      <h4>{{ product.name }}</h4>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ product.orders ? product.orders.length : 0 }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="80" color="grey">
                    <v-img
                      v-if="dishImage(product)"
                      contain
                      :src="dishImage(product)"
                    ></v-img>
                    <v-icon class="grey--text" v-else
                      >mdi-image-off-outline</v-icon
                    >
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        label="QTY"
                        v-model="product.amount_left"
                        :rules="[rules.amountMin]"
                      />
                    </v-col>
                    <v-col sm="6">
                      <v-text-field
                        label="%"
                        v-model="product.off"
                        :rules="[rules.offMin, rules.offMax]"
                      />
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </draggable>
        </div>
      </v-row>
    </div>

    <!-- SUB MENUS -->

    <v-row>
      <v-snackbar :timeout="6000" color="red" v-model="editSnackbar">
        Missing Schedule End Time!
        <v-btn color="white" text @click="editSnackbar = false">Close</v-btn>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { globalMixin } from 'fod-core'

export default {
  mixins: [globalMixin],

  data: () => {
    return {
      defaultOff: '15',
      description: null,

      products: [],
      targetProduct: '',
      totalMenus: 0,
      targetDay: '',
      tmp: null,
      menu_id: null,
      lastCall: { items: [] },

      startHour: null,
      editSnackbar: false,
      search: null,
      notInLastCall: false,
      subMenus: [],
      targetMenu: '',
      draggableSubMenu: [],
      rules: {
        required: value => !!value || 'Required.',
        amountMin: value => value > 0 || 'Amount should be above 0',
        offMin: value => value >= 0 || 'Min should be above 0',
        offMax: value => value <= 100 || 'Max should not be above 100'
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      dishList: 'dishList'
    }),

    endHour() {
      return this.eachHalfHour(
        this.convertTime(this.lastCall.fromHour),
        '23:30'
      )
    },
    dragOptions() {
      return {
        group: 'lastCall'
      }
    },
    filteredDishList() {
      let list = this.dishList
      if (this.search) {
        list = this.dishList.filter(
          p => p.name.toLowerCase().indexOf(this.search.toLowerCase()) == 0
        )
      }
      if (this.notInLastCall) {
        if (this.lastCall && this.lastCall.items) {
          list = list.filter(p => !this.lastCall.items.includes(p.id))
        }
      }
      return list
    }
  },

  components: {
    draggable
  },

  mounted() {
    this.$store.dispatch('loadDishList')
    //this.loadMenu(this.$route.params.id)
    this.$store.dispatch('getLastCall', { type: 'default' }).then(l => {
      if (l && l.length > 0) {
        this.lastCall = l[0]
        this.description = this.lastCall.description || ''
        this.defaultOff = this.lastCall.defaultOff || 15
      }
    })

    this.startHour = this.eachHalfHour('6:00', `23:30`)
  },

  methods: {
    addProduct(product) {
      console.log('add', product)
    },
    deleteMenu(id) {
      this.menu_id = id
      this.$refs.deleteConfirmationDialog.openDialog()
    },

    dishImage(dish) {
      return dish && dish.photos && dish.photos.length > 0
        ? dish.photos[0].url || dish.photos[0].imageUrl
        : null
    },
    removeProduct: function(id, mid) {
      console.log(`Remove ${id} from  ${mid}`)

      if (!this.lastCall) {
        return
      }

      const list = this.lastCall.items.filter(item => item.product_id !== id)
      this.lastCall.items = list
      this.$store.dispatch('saveLastCall', this.lastCall).then(() => {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('setMessage', { title: 'Saved' })
      })
    },
    removeMenu: function(subId, parentId) {
      console.log(`Removed ${subId} from  ${parentId}`)
      if (!this.lastCall) {
        return
      }
      const list = this.lastCall.children.filter(item => item !== subId)
      this.lastCall.children = list

      this.$store.dispatch('saveLastCall', this.menu).then(() => {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('setMessage', { title: 'Saved' })
      })
    },
    productInLastCall(items) {
      let list = []

      if (!items) {
        return
      }

      items.items.forEach(selected => {
        let products = this.dishList.filter(p => p.id === selected.product_id)
        const product = products[0]
        product.off = selected.off
        product.amount_left = selected.amount_left
        list.push(product)
      })

      return list
    },

    // this is called when a new product is dropped into a day
    newProduct: function(id) {
      console.log('newProduct ' + id)
      this.lastCall.items = this.lastCall.items || []
      let list = this.lastCall.items.filter(p => p === this.targetProduct)
      if (list.length > 0) {
        console.log('already exists')
        return
      }
      let d = this.dishList.filter(d => d.id === this.targetProduct)[0]
      console.log(d)
      const dish = {
        id: d.id,
        off: d.off || null,
        price: d.price || null,
        amount_left: d.amount_left || null
        /* name: d.name,
        description: d.description || null,

        chef: d.chef || null,

        menus: d.menus || null,
        overall_rating: d.overall_rating || null,
        ratings: d.ratings || null,
        visibility: d.visibility || null,

        photos: d.photos || null*/
      }
      if (
        this.lastCall.items.length > 0 &&
        this.lastCall.items
          .map(item => item.product_id)
          .includes(this.targetProduct)
      )
        return

      this.lastCall.items.push({
        dish: dish,
        product_id: this.targetProduct,
        amount_left: parseInt(dish.amount_left),
        off: dish.off,
        price: dish.price - (dish.price * dish.off) / 100
      })

      /*this.$store.dispatch('saveLastCalls', menu).then(() => {
        this.$store.dispatch('setLoading', false)
      })*/
      //console.log(event)
      //eventBus.$emit('daySelected', event.to.id)
    },
    // @param: parent menu id

    chooseProduct: function(event) {
      console.log('chooseProduct ' + event.from.id)
      let dish = this.dishList.filter(d => d.id === event.from.id)[0]
      dish.off = dish.off ? dish.off : this.defaultOff

      if (!this.dishList.filter(d => d.id === event.from.id)[0].amount_left) {
        alert('QTY is missing')
        return
      }
      this.targetProduct = event.from.id
    },
    chooseMenu: function(event) {
      console.log('chooseMenu ' + event.from.id)
      this.targetMenu = event.from.id
    },
    sendProductData: function(id) {
      console.log('id ' + id)
      // var myProduct = this.products.filter(function(product) {
      //   return product.id == id
      // })
      // var productData = {
      //   day: this.targetDay,
      //   product: myProduct
      // }
      //eventBus.$emit('sendingProduct', productData)
    },

    checkSchedule() {
      // shows alert if times are empty for menu
      if (
        this.lastCall.repeat &&
        (!this.menlastCallu.fromHour || !this.lastCall.endHour)
      ) {
        return false
      } else {
        return true
      }
    },
    saveLastCall() {
      this.lastCall.description = this.description
      this.lastCall.defaultOff = this.defaultOff
      console.log(this.lastCall, ' before saveLastCall')
      this.$store.dispatch('saveLastCall', this.lastCall).then(() => {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('setMessage', { title: 'Last call saved' })
      })
    },
    convertTime(time) {
      if (time) {
        // converts 12 hour to 24 hour
        const [hour, modifier] = time.split(' ')
        let [hours, minutes] = hour.split(':')
        if (hours === '12') {
          hours = '00'
        }
        if (modifier === 'pm') {
          hours = parseInt(hours, 10) + 12
        }
        return `${hours}:${minutes}`
      }
    }
  }
}
</script>
<style scoped>
.v-small-dialog {
  width: 80%;
}
</style>
